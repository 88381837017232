.banner-section {
    background: linear-gradient(60deg, rgba(212, 252, 120, 0.03) 0%, rgba(223, 214, 119, 0.29) 100%), #FFF;
    padding: 20px 0;
}

.banner-section__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}

.banner-section__title {
    font-weight: 700;
    font-size: 40px;
    color: rgb(244, 196, 0);
}

.banner-section__btn {
    width: 30% !important;
    font-size: 15px;
    border-radius: 40px;
}

@media screen and (max-width: 1100px){
    .banner-section__inner {
        flex-direction: column-reverse;
        gap: 20px;
        text-align: center;
    }

    .banner-section__title {
        font-size: 30px;
    }

    .banner-section__btn {
        width: 100% !important;
    }
}

@media screen and (max-width: 600px) {
    .banner-section__img > img {
        width: 65%;
        height: auto;
    }
}