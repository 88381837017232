.credit-section__inner {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 50px;
}

.credit-section__title {
    font-weight: 700;
    font-size: 40px;
    color: rgb(244, 196, 0);
}

.credit-section__text {
    font-size: 20px;
}

.credit-section__image {
    position: relative;
    display: inline-block;
}

.credit-section__image > img {
    border-radius: 50px;
}

@media screen and (max-width: 950px) {
    .credit-section__inner {
        flex-direction: column;
        gap: 20px;
        text-align: center;
    }

    .credit-section__title {
        font-size: 30px;
    }

    .credit-section__text {
        font-size: 16px;
    }

    .credit-section__image > img {
        width: 50%;
        height: auto;
    }
}

@media screen and (max-width: 650px) {
    .credit-section__image > img {
        width: 65%;
        height: auto;
    }
}

@media screen and (max-width: 450px) {
    .credit-section__image > img {
        width: 80%;
        height: auto;
    }
}



