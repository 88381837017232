.form {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    width: 100%;
    height: 110%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-container {
    border-radius: 20px;
    padding: 50px 100px;
    background-color: #f2f6f8;
    position: relative;
}

.form-title {
    font-weight: 700;
    font-size: 40px;
    line-height: 100%;
    letter-spacing: .02em;
    color: #424141;
    margin-bottom: 50px;
}

.form-content__personal-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 20px;
}

.form-content__info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 10px;
}

.form-content-name__title {
    color: #f4c400;
    font-size: 20px;
}

.form-content__info label {
    font-size: 16px;
    color: #969ca6;
    margin-bottom: 5px;
}

.form-content__info span, .form-content__chooser-container span {
    color: red;
}

.form-content__info input {
    width: 100%;
    height: 50px;
    padding: 10px;
    font-family: Circe Regular, sans-serif;
    font-size: 20px;
    border: 1px solid #d2dae4;
    border-radius: 10px;
    box-sizing: border-box;
}

.form-content__info input:focus {
    outline: none;
    border: 1px solid #f4c400;
}

.form-content__info input:disabled {
    cursor: not-allowed;
    background-color: transparent;
    color: #e5e8ec;
}

.form-content__gender-chooser {
    margin-left: 0;
    margin-bottom: 20px;
}

.form-content__chooser-buttons {
    display: flex;
    gap: 10px;
}

.form-content__chooser-container .heading {
    font-size: 16px;
    color: #969ca6;
}

.form-content__male,
.form-content__female {
    display: flex;
    align-items: center;
    width: 15%;
    gap: 5px;
    font-size: 20px;
    border: 1px solid #d2dae4;
    border-radius: 10px;
    padding: 0 0 0 10px;
    height: 50px;
    margin-top: 10px;
}

input[type="radio"] {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #d2dae4;
    -webkit-appearance: none;
}

input[type="radio"]::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: transparent;
}

input[type="radio"]:checked::after {
    background-color: #f4c400;
}

.form-content__checkbox {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.form-content__checkbox input {
    margin-right: 10px;
}

.form-content__actions {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
}

.form-content__submit {
    background-color: #d2dae4;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 20px;
    color: #fff;
    cursor: not-allowed;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-content__submit.active {
    background-color: #f4c400;
    cursor: pointer;
}

.form-content__submit .arrow {
    margin-left: 10px;
    font-size: 20px;
}

.form-success-message {
    font-size: 18px;
    font-weight: bold;
    color: green;
    text-align: center;
}

.form-close-button {
    position: absolute;
    top: 25px;
    right: 30px;
    font-size: 30px;
    color: #969ca6;
    background-color: unset;
    cursor: pointer;
    border: 0;
}

input::placeholder {
    color: #969ca6;
}

@media screen and (max-width: 1200px) {
    .form-container {
        padding: 50px;
        width: auto;
        margin: 0 20px;
    }
}

@media screen and (max-width: 1000px) {
    .form-container {
        padding: 30px;
        overflow: scroll;
        width: 90%;
        height: 80%;
    }

    .form-title {
        font-size: 35px;
        /*margin-bottom: 5px;*/
    }

    .form-content__personal-info {
        flex-direction: column;
        gap: 5px;
        margin-bottom: 0;
    }

    .form-content__chooser-buttons {
        width: 100%;
    }

    .form-content__male, .form-content__female {
        width: 50%;
    }

    .form-content__actions {
        margin-top: 0;
        flex-direction: column;
        gap: 20px;
        font-size: 18px;
        padding: 0;
    }
}

@media screen and (max-width: 400px) {
    .form-container {
        padding: 20px;
    }

    .form-title {
        width: 90%;
    }

    .form-content__info input {
        font-size: 16px;
    }

    .form-content__info label {
        font-size: 14px;
    }

    .form-content__submit {
        font-size: 16px;
    }

    .form-content__submit .arrow {
        font-size: 16px;
    }

    .form-content__personal-info {
        gap: 10px;
    }

    .form-close-button {
        top: 18px;
        right: 20px;
    }
}