.howget__container {
    padding: 44px 0;
}

.howget__cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
}

.howget__cards-list {
    margin: 0 0 30px;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
}

.howget__cards-item {
    flex: 1;
    max-width: calc((100% - 40px) / 3);
    display: flex;
    align-items: center;
}

.howget__card {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    background-color: white;
    border-radius: 8px;
}

.howget__cards-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 65px;
    min-height: 65px;
    background-color: rgba(244, 196, 0, 0.58);
    border-radius: 50%;
    color: white;
    margin: 0 auto;
}

.howget__cards-text {
    display: flex;
    flex-direction: column;
}

.howget__cards-title {
    font-size: 18px;
    margin: 0;
    color: rgb(244, 196, 0);
}

.howget__card-description {
    font-size: 14px;
    margin: 0;
    color: #666;
}

.howget__card-button {
    width: 30% !important;
    margin: 0 auto;
}

@media screen and (max-width: 880px) {
    .howget__cards-list {
        gap: 70px;
        flex-wrap: wrap;
    }

    .howget__cards-item {
        max-width: 100%;
    }
}

@media screen and (max-width: 750px) {
    .howget__title {
        text-align: center;
    }
    .howget__cards-list {
        gap: 50px;
    }

    .howget__cards-item {
        max-width: 100%;
    }

    .howget__card-button {
        width: 100% !important;
    }
}

@media screen and (max-width: 550px) {
    .howget__cards-list {
        gap: 25px;
    }

    .howget__cards-item {
        max-width: 100%;
    }
}

