.header-top {
    border-bottom: 1px solid #E8E8E8;
    background: linear-gradient(180deg, rgba(212, 252, 120, 0.03) 0%, rgba(223, 214, 119, 0.29) 100%), #FFF;;
}

.header-top__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px 0;
}

.header-top__user-panel {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    font-weight: bold;
    color: #FF5C00;
}

.header-top__user-panel > svg {
    font-size: 18px;
}

.header-top__panel {
    color: #FF5C00;
    font-size: 18px;
    text-decoration: none;
}

.header-bottom {
    padding: 20px 0;
}

.header-bottom__menu {
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
    gap: 40px;
}

.header-bottom__menu-item > a {
    font-size: 16px;
    text-decoration: none;
    color: unset;
}

@media screen and (max-width: 800px) {
    .header-bottom {
        display: none;
    }
}

@media screen and (max-width: 400px) {
    .header-top__user-panel > svg {
        font-size: 30px;
    }
    .header-top__panel {
        display: none;
    }
}