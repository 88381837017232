.advantages-section {
    background: linear-gradient(60deg, rgba(212, 252, 120, 0.03) 0%, rgba(223, 214, 119, 0.29) 100%), #FFF;
    padding: 44px 0;
    margin-bottom: 30px;
}

.advantages-cards__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    gap: 40px;


}

.advantages-cards__item {
    flex: 1;
    max-width: calc((100% - 40px) / 4);
    display: flex;
    align-items: center;
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.advantages-cards__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 45px;
    color: #f6e224;
    margin-bottom: 20px;
}

.advantages-cards__text {
    text-transform: uppercase;
    text-align: center;
}

.advantages-cards__title {
    font-size: 17px;
    margin: 0;
}

@media screen and (max-width: 800px) {
    .advantages-cards__list {
        flex-wrap: wrap;
    }

    .advantages-cards__item {
        max-width: 100%;
        justify-content: center;
    }
}

@media screen and (max-width: 550px) {
    .advantages-cards__list {
        flex-wrap: wrap;
        flex-direction: column;
    }
}