@import url("fonts/fonts.css");

body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Mulish', sans-serif;
}


img {
  pointer-events: none;
}

