.calculation__section {
    padding: 44px 0;
    background: linear-gradient(60deg, rgba(212, 252, 120, 0.03) 0%, rgba(223, 214, 119, 0.29) 100%), #FFF;
}

.calculation__title {
    color: rgb(244, 196, 0);
    margin: 0 0 20px 0;
    font-size: 52px;
    font-weight: 700;
}

.calculation__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 100px;
}

.calculation__description {
    font-size: 16px;
    font-weight: 400;
    margin: 0 0 20px 0;
    color: #898989;
}

.calculator__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.calculator__item {
    font-size: 22px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #1d1d1f;
}

.calculator-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.calculator-block__calculator {
    background: #FFF;
    max-width: 360px;
    width: 360px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.calculator-block__container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.calculator-block__range-block {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.calculator_block__range-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.calculator-block__form label {
    margin-bottom: 5px;
    display: block;
}

.calculator-block__buttons {
    display: flex;
    align-items: center;
}

.calculator-block__buttons button {
    background: #FFF;
    border: 1px solid #CCC;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.calculator-block__buttons span {
    font-size: 20px;
    margin: 0 5px;
    font-weight: bold;
}

.calculator-block__total-block {
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
    gap: 10px;
}

.calculator-block__total-text {
    font-size: 16px;
}

.calculator-block__button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.button-primary {
    background: #EADC40;
    background: linear-gradient(135deg, #EADC40, #CEBB0D);
    color: #FFF;
    border: none;
    padding: 15px 20px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 700;
    font-size: 22px;
    width: 100%;
}

input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    background: transparent;
    outline: none;
    margin: 10px 0;
}

input[type="range"]:hover {
    opacity: 1;
}

input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    background: #f4c400;
    border-radius: 4px;
}

input[type="range"]::-moz-range-track {
    width: 100%;
    height: 8px;
    background: #f4c400;
    border-radius: 4px;

}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #f4c400;
    cursor: grab;
    border-radius: 50%;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    border: 2px solid #fff;
    margin-top: -7px;
}

input[type="range"]::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #f4c400;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    border: 2px solid #fff;

}

@media (max-width: 1052px) {
    .calculation__title {
        font-size: 47px;
    }

    .calculator__item {
        font-size: 20px;
    }
}

@media (max-width: 880px) {
    .calculation__container {
        flex-direction: column;
        gap: 40px;
    }

    .calculation__header {
        text-align: center;
    }

    .calculation__title {
        font-size: 50px;
    }

    .calculator__list {
        align-items: center;
        gap: 5px;
    }
}

@media screen and (max-width: 500px) {
    .calculation__title {
        font-size: 40px;
    }

    .calculation__description {
        font-size: 14px;
    }

    .calculator__item {
        font-size: 4vw;
    }

    .calculator-block__calculator {
        width: 100%;
    }
}

@media screen and (max-width: 380px) {
    .calculator-block__total-block {
        flex-direction: column;
        gap: 10px;
    }
}

@media screen and (max-width: 325px) {
    .calculator_block__range-info {
        flex-direction: column;
        gap: 10px;
    }

    .calculator-block__notice {
        text-align: center;
    }
}