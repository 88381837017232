.faq-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 20px;
}

.faq-title {
    flex: 0 0 100%;
    font-weight: 700;
    font-size: 40px;
    color: rgb(244, 196, 0);
    margin: 0 0 20px;
}

.faq-list {
    display: flex;
    flex-wrap: wrap;
}

.faq-item {
    flex: 1 1 calc(50% - 16px);
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    margin: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.faq-answer {
    margin-top: 16px;
}

.faq-question:hover {
    color: rgb(244, 196, 0);
    transition: 0.2s;
}

@media screen and (max-width: 800px) {
    .faq-container {
        padding: 20px 0;
    }

    .faq-title {
        text-align: center;
        font-size: 30px;
    }
}